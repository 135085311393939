import art1 from "../../imgs/art1.svg";
import art2 from "../../imgs/art2.svg";
import art3 from "../../imgs/art3.svg";
import white_logo from "../../imgs/white-logo.svg";
import myPic from "../../imgs/my-picture.png";
import Glasses from "../Glasses";
import { useState } from "react";
import { Link } from "react-router-dom";
import { send } from "emailjs-com";
import classNames from "classnames";
import "./home.css";
import { works } from "../../data/work";
import Work from "../work/Work";
import Modal from "../modal/Modal";

export default function Home() {
  const [isDarkTheme, setIsDarkTheme] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
    reply_to: "",
  });

  const handleChangeMessage = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    send("service_qd26t5b", "template_5sgsh3h", toSend, "wErNeEq_WzP4O7k8F")
      .then((response) => {
        setIsSent(true);
      })
      .catch((err) => {
        console.log("FAILED...", err);
      });
  };

  const changeTheme = () => {
    if (window.scrollY >= 1040 && window.scrollY <= 2000) {
      setIsDarkTheme(true);
    } else {
      setIsDarkTheme(false);
    }
    console.log(window.scrollY, isDarkTheme);
  };

  window.addEventListener("scroll", changeTheme);

  return (
    <div
      className={classNames("home-page", {
        "home-page--dark": isDarkTheme,
      })}
    >
      <div className="home-page__land starter">
        <div className="home-page__land-container">
          <h1 className="home-page__land-welcome">Welcome to My World!</h1>
          <h2 className="home-page__land-job">Front-End Developer</h2>
        </div>

        <img
          className="home-page__white-logo"
          src={white_logo}
          alt="white logo"
        />
        <img className="art" src={art1} alt="arts" />
        <img className="art" src={art2} alt="arts" />
        <img className="art" src={art3} alt="arts" />
        <img className="art" src={art3} alt="arts" />
        <img className="art" src={art3} alt="arts" />
        <span className="y-dash"></span>
        <span className="y-dash"></span>
        <span className="y-dash"></span>
        <span className="y-dash"></span>
        <span className="y-dash"></span>
        <span className="y-dash"></span>
        <span className="y-dash"></span>
      </div>

      <div className="home-page__about-section" id="about">
        <h1 className="heading">About Me </h1>

        <div className="home-page__about-section-wrapper">
          <div className="home-page__about-section-side">
            <div className="f-holder">
              <img
                className="home-page__my-pic"
                src={myPic}
                data-aos="zoom-in-up"
                alt="my persnoal pic"
              />
            </div>
          </div>
          <div className="home-page__about-section-side">
            <h2
              className={classNames("home-page__my-name", {
                "home-page__my-name--white-text": isDarkTheme,
              })}
              data-aos="zoom-in-up"
            >
              Mohammed Bin Sahl
            </h2>
            <h3
              className={classNames("home-page__my-job", {
                "home-page__my-job--white-text": isDarkTheme,
              })}
              data-aos="zoom-in-up"
            >
              Front-End Developer
            </h3>

            <p
              className={classNames("home-page__my-bio", {
                "home-page__my-bio--white-text": isDarkTheme,
              })}
              data-aos="zoom-in-up"
              data-aos-delay="300"
            >
              Junior Front-End Developer with a passion for creating innovative,
              user-friendly web applications. Experienced in HTML, CSS,
              JavaScript, and ReactJS. Skilled at delivering modern designs
              while optimizing performance and scalability. Driven to help teams
              succeed by leveraging problem solving strategies and communication
              skills.
            </p>
          </div>
        </div>

        <Link
          to="/about"
          className={classNames("home-page__about-link", {
            "home-page__about-link--white-text": isDarkTheme,
          })}
        >
          More About Me...
        </Link>
      </div>
      <hr />

      <div className="home-page__works-section" id="work-sec">
        <h1
          className={classNames("heading", {
            yellow: isDarkTheme,
          })}
        >
          The Best of My{" "}
          <span
            className={classNames("", {
              "home-page__change": isDarkTheme,
            })}
          >
            Work
          </span>
        </h1>

        <div className="home-page__work-items">
          {works.slice(0, 3).map((item) => (
            <Work
              description={item.describe}
              link={item.url}
              title={item.title}
              key={item.title}
              image={
                !item.image.includes(".")
                  ? require(`../../imgs/${item.image}.jpg`)
                  : require("../../imgs/" + item.image)
              }
            />
          ))}
        </div>
        <div className="row pt-5">
          <div className="f-holder">
            <Link
              to="/portfolio"
              className={classNames("view-btn", {
                "view-btn--light-theme": !isDarkTheme,
              })}
            >
              Watch more amazing Projects
            </Link>
          </div>
        </div>
      </div>

      <hr />

      <div className="container-fluid need-sec">
        <Glasses eyesColor={"white"} />

        <h1 className="need-text text-center">
          <span>NEED </span>
          <span>A</span>
          <span className="programmer-word text-white"> PROGRAMMER__ </span>
          <span>?</span>
        </h1>
      </div>

      <div className="home-page__contact-section" id="contact">
        <h1 className="heading">Contact Me </h1>

        <form onSubmit={onSubmit} className="home-page__contact-form">
          <div className="home-page__form-field">
            <label htmlFor="name">Name</label>
            <input
              className="home-page__form-control"
              id="name"
              type="text"
              name="from_name"
              value={toSend.from_name}
              onChange={handleChangeMessage}
            />
          </div>
          <div className="home-page__form-field">
            <label htmlFor="name">email</label>
            <input
              className="home-page__form-control"
              id="email"
              type="email"
              name="reply_to"
              value={toSend.reply_to}
              onChange={handleChangeMessage}
            />
          </div>
          <div className="home-page__form-field">
            <label htmlFor="msg">Your Message</label>
            <textarea
              rows="5"
              className="home-page__form-control"
              id="msg"
              type="text"
              name="message"
              value={toSend.message}
              onChange={handleChangeMessage}
            />
          </div>
          <button className="view-btn view-btn--light-theme" type="submit">
            Send
          </button>
        </form>
      </div>

      <Modal isOpen={isSent} onClose={() => setIsSent(false)}>
        <h1 className="message">Your message sent Successfully!</h1>
      </Modal>
    </div>
  );
}

export const works = [
  {
    title: "Binsahl Store",
    describe:
      "E-commerece Store for deffint items.",
    image: "binsahl.png",
    url: "https://binsahl.com",
  },
  {
    title: "Yadawi Store",
    describe:
      "E-commerece Store for hand made products for all diffrent cultures.",
    image: "site13",
    url: "https://yadawystore.netlify.app/",
  },
  {
    title: "Wheather App",
    describe: "Check the weather status in your city.",
    image: "site12",
    url: "https://mohammedbinsahl6.github.io/weather-app/",
  },
  {
    title: "In Car",
    describe: "In Car is company provides best sales in cars.",
    image: "site10",
    url: "https://mohammedbinsahl6.github.io/inCar/",
  },
  {
    title: "Mark June Portofolio",
    describe: "Mark June is a Photographer and this is his website",
    image: "site11",
    url: "https://mohammedbinsahl6.github.io/mark-web/",
  },
  {
    title: "Honey Hotel",
    describe: "best hotels guide site",
    image: "site3",
    url: "https://mohammedbinsahl6.github.io/MY-WEBSITE-/works/site3/index.html",
  },
  {
    title: "5G STORE",
    describe: "ELCTRONIC SHOP ,SHOPPING ONLINE",
    image: "site7",
    url: "https://mohammedbinsahl6.github.io/MY-WEBSITE-/works/site7/index.html",
  },
  {
    title: "PHONE DETAILS",
    describe: "featurs for ur phone.",
    image: "site8.png",
    url: "https://phone-details-preview.netlify.app/",
  },
  {
    title: "Choco Cafe",
    describe: "Chocolate cafe and resturant.",
    image: "site4",
    url: "https://mohammedbinsahl6.github.io/MY-WEBSITE-/works/site4/index.html",
  },
  {
    title: "Calculater App",
    describe: "Calculater and calc your age .",
    image: "site5",
    url: "https://mohammedbinsahl6.github.io/MY-WEBSITE-/works/site5/index.html",
  },
  {
    title: "The Best shakshooka",
    describe: "Modern arabic resturant ",
    image: "site2",
    url: "https://mohammedbinsahl6.github.io/MY-WEBSITE-/works/site2/index.html",
  },
  {
    title: "Graphic Designer",
    describe: "Graphic Designer Portfolio.",
    image: "site1",
    url: "https://mohammedbinsahl6.github.io/MY-WEBSITE-/works/site1/index.html",
  },
  //,
  // {
  //     title : 'Yadawi Store',
  //     describe : 'E-commerece Store for hand made products for all diffrent cultures.',
  //     image : 'site13',
  //     url : 'https://yadawystore.netlify.app/'

  // },
  // {
  //     title : 'Yadawi Store',
  //     describe : 'E-commerece Store for hand made products for all diffrent cultures.',
  //     image : 'site13',
  //     url : 'https://yadawystore.netlify.app/'

  // },
  // {
  //     title : 'Yadawi Store',
  //     describe : 'E-commerece Store for hand made products for all diffrent cultures.',
  //     image : 'site13',
  //     url : 'https://yadawystore.netlify.app/'

  // },
  // {
  //     title : 'Yadawi Store',
  //     describe : 'E-commerece Store for hand made products for all diffrent cultures.',
  //     image : 'site13',
  //     url : 'https://yadawystore.netlify.app/'

  // },
  // {
  //     title : 'Yadawi Store',
  //     describe : 'E-commerece Store for hand made products for all diffrent cultures.',
  //     image : 'site13',
  //     url : 'https://yadawystore.netlify.app/'

  // },
  // {
  //     title : 'Yadawi Store',
  //     describe : 'E-commerece Store for hand made products for all diffrent cultures.',
  //     image : 'site13',
  //     url : 'https://yadawystore.netlify.app/'

  // },
];

import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import TodoItem from "./TodoItem";

const initialTodo = {
  id: uuidv4(),
  title: "",
  body: "",
  done: false,
};
const getTodos = localStorage.getItem("todos");

export default function Todo() {
  const [todos, setTodos] = useState([]);
  const [todo, setTodo] = useState(initialTodo);

  const onAdd = (e) => {
    e.preventDefault();

    setTodos((todos) => [...todos, todo]);
    // let todosData = JSON.stringify(todos);

    setTodo(initialTodo);
    localStorage.setItem("todos", JSON.stringify(todos));
  };

  const onDone = (id) => {
    setTodos((prev) => {
      return prev.map((item) => {
        if (item.id === id) return { ...item, done: !item.done };
        return item;
      });
    });
  };

  const cleanAllTodos = () => {
    setTodos([]);
    localStorage.clear();
  };

  useEffect(() => {
    if (getTodos) {
      let data = JSON.parse(getTodos);
      setTodos(data);
    }
  }, []);

  useEffect(() => {
    let todosData = JSON.stringify(todos);
    localStorage.setItem("todos", todosData);
  }, [todos]);
  return (
    <div className=".container-fluid todo-page m-5">
      <div className="row p-3">
        <h1 className="text-center head">My Todo for Today</h1>
      </div>

      <div className="row p-3">
        <div className="col-sm-6 f-holder">
          <form onSubmit={onAdd} className="bg-1">
            <div className="my-3">
              <input
                className="form-control"
                type="text"
                placeholder="title"
                value={todo.title}
                onChange={(e) => setTodo({ ...todo, title: e.target.value })}
              />
            </div>
            <div className="my-3">
              <textarea
                rows={3}
                className="form-control"
                type="text"
                placeholder="todo something..."
                value={todo.body}
                onChange={(e) => setTodo({ ...todo, body: e.target.value })}
              />
            </div>
            <button
              className="view-btn m-3"
              type="submit"
              disabled={todo.title.trim() === ""}
            >
              Add
            </button>
          </form>
        </div>
        <div className="col-sm-6 f-holder">
          {todos.length === 0 ? (
            <h4 className="text-center">No Todos yet !</h4>
          ) : (
            <div className="row p-3">
              <button className="btn btn-danger m-3" onClick={cleanAllTodos}>
                Clean All
              </button>
              {todos?.map((todo) => (
                <TodoItem key={todo.id} todo={todo} onDone={onDone} />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
